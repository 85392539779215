// Show a form
// get islandCode, by default use 6178-2350-0998
// When submitting call the firebase httpsCallable function "scrapfn-checkIslandStats" with the islandCode in the payload
<template>
  <div>
    <!-- Show a form -->
    <form @submit.prevent="submitForm">
      <label for="islandCode">Island Code:</label>
      <input type="text" id="islandCode" v-model="islandCode" />
      <button type="submit">Submit</button>
      <debug-obj :objData="result" expand />
    </form>
  </div>
</template>

<script>
import { getLog } from "@/services/log";
import { checkIslandStats } from '@/services/functions';
const log = getLog("TestFNStatsIsland");

export default {
  components: {
    'DebugObj' : () => import('@/components/debugObj.vue'),
  },
  data() {
    return {
      islandCode: "6178-2350-0998", // get islandCode, by default use 6178-2350-0998
      result: null,
    };
  },
  methods: {
    async submitForm() {
      try {
        // When submitting call the firebase httpsCallable function "scrapfn-checkIslandStats" with the islandCode in the payload
        const result = await checkIslandStats({ islandCode: this.islandCode });
        log.log("result:", result.data);
        this.result = result.data;
      } catch (error) {
        log.log(error);
      }
    },
  },
};
</script>
